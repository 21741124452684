exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-gdpr-de-js": () => import("./../../../src/pages/gdpr.de.js" /* webpackChunkName: "component---src-pages-gdpr-de-js" */),
  "component---src-pages-impressum-de-js": () => import("./../../../src/pages/impressum.de.js" /* webpackChunkName: "component---src-pages-impressum-de-js" */),
  "component---src-pages-index-de-js": () => import("./../../../src/pages/index.de.js" /* webpackChunkName: "component---src-pages-index-de-js" */),
  "component---src-pages-index-en-js": () => import("./../../../src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-referenzen-masterdata-management-system-de-js": () => import("./../../../src/pages/referenzen/masterdata-management-system.de.js" /* webpackChunkName: "component---src-pages-referenzen-masterdata-management-system-de-js" */),
  "component---src-pages-test-de-js": () => import("./../../../src/pages/test.de.js" /* webpackChunkName: "component---src-pages-test-de-js" */),
  "component---src-pages-whitepapers-warum-offshore-outsourcing-de-js": () => import("./../../../src/pages/whitepapers/warum-offshore-outsourcing.de.js" /* webpackChunkName: "component---src-pages-whitepapers-warum-offshore-outsourcing-de-js" */)
}

